import { Modal, Container } from 'react-bootstrap';
import styled from 'styled-components';
import crossImage from '../../images/crossImage.png';
import { Link } from 'gatsby';

export const ParaText = styled.div`
  font-family: 'Figtree-Bold', arial, sans-serif;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6e2a8d;
`;

export const LinkStyle = styled.a`
  color: #4d1367;
  text-decoration: none;
  display: block;
  position: relative;
  margin: 7px auto 0 auto;
  font-family: 'Figtree-Light', arial, sans-serif;
  text-transform: none;
  font-size: 50px;
  line-height: 50px;
  opacity: 0.85;
  @media (max-width: 991px) {
  }
  @media (max-width: 767px) {
  }
  :hover {
    color: #e8a300;
  }
`;
export const LinkStyleInternal = styled(Link)`
  color: #4d1367;
  text-decoration: none;
  display: block;
  position: relative;
  margin: 7px auto 0 auto;
  font-family: 'Figtree-Light', arial, sans-serif;
  text-transform: none;
  font-size: 50px;
  line-height: 50px;
  opacity: 0.85;
  @media (max-width: 991px) {
  }
  @media (max-width: 767px) {
  }
  :hover {
    color: #e8a300;
  }
`;

export const MainModal = styled(Modal)`
  border: none !important;
  background: none !important;
  text-align: center;
  .modal-dialog {
    max-width: 600px !important;
  }
  .modal-content {
    max-height: 350px !important;
  }
`;

export const CustomerSelect = styled(Container)`
  padding: 40px 0;
  background: #fff url(${(props) => props.$imageUrl}) no-repeat center -100px;
  max-width: 100% !important;
  overflow: hidden;
  @media (max-width: 767px) {
    padding-top: 20px !important;
  }
`;

export const CloseImage = styled.a`
  background: url(${crossImage});
  position: absolute;
  right: -18px;
  top: -18px;
  width: 36px;
  height: 36px;
`;
